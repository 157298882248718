import IMAGES from "assets";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";

const url = process.env.REACT_APP_PUBLIC_ENDPOINT_API;

const MangaLayer = ({ background, layers = [] }) => {
  const containerRef = useRef(null);
  const backgroundRef = useRef();
  const [isVisible, setIsVisible] = useState(false);
  const [ratioX, setRatioX] = useState(null);
  useEffect(() => {
    const handleImageLoad = () => {
      if (backgroundRef.current && containerRef.current) {
        const widthContainer = containerRef.current.clientWidth;
        const widthX = backgroundRef.current.naturalWidth;

        if (widthX > 0) {
          setRatioX(widthContainer / widthX);
        }
      }
    };

    const imgElement = backgroundRef.current;

    if (backgroundRef.current) {
      if (backgroundRef.current.complete) {
        handleImageLoad();
      } else {
        backgroundRef.current.addEventListener("load", handleImageLoad);
      }
    }

    return () => {
      if (imgElement) {
        imgElement.removeEventListener("load", handleImageLoad);
      }
    };
  }, [backgroundRef]);

  useEffect(() => {
    if (layers.length === 0 || ratioX === null) return;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(containerRef.current);
      }
    };
  }, [layers.length, ratioX]);

  return (
    <div
      ref={containerRef}
      className="relative w-full h-auto m-auto !max-w-[700px] mb-5 border border-slate-300 overflow-hidden"
    >
      <img
        ref={backgroundRef}
        src={`${url}/images/${background}`}
        alt="background"
        className={`w-full h-auto block z-[1]`}
        onError={(e) => {
          e.target.src = IMAGES.imageNoImage;
        }}
      />
      {layers.map((layer, index) => {
        if (!ratioX) return <React.Fragment key={index}></React.Fragment>;
        return (
          <img
            key={index}
            src={`${url}/images/${layer.src}`}
            alt={`layer-${index}`}
            className={classNames(
              "absolute object-cover",
              isVisible ? "block" : "hidden",
              layer.style && layer.style.animation
                ? String(layer.style.animation)
                : ""
            )}
            style={{
              width: Math.floor(ratioX * layer.width) + "px",
              height: "auto",
              top: Math.floor(ratioX * layer.top) + "px",
              left: Math.floor(ratioX * layer.left) + "px",
              zIndex: index + 2,
            }}
            onError={(e) => {
              e.target.style.display = "none";
            }}
          />
        );
      })}
    </div>
  );
};

export default MangaLayer;
