import MangaLayer from "components/MangaLayer";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getDetailStory } from "services/webtoon";

const MangaViewer = () => {
  const { slug } = useParams();
  const [story, setStory] = useState(null);

  const fetchData = async () => {
    try {
      const res = await getDetailStory(slug);
      setStory(res);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {story?.pages?.map((page, index) => (
        <MangaLayer
          key={index}
          background={page.background}
          layers={page.layers}
        />
      ))}
    </div>
  );
};

export default MangaViewer;
