import React from "react";

const Loading = () => {
  return (
    <div className="w-[100dvw] h-[100dvh] fixed z-[9999] top-0 left-0 bg-[rgba(255,255,255,0.2)]">
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <div className="custom-loader"></div>
      </div>
    </div>
  );
};

export default Loading;
