import classNames from "classnames";
import { ANIMATION_TYPE } from "constants";
import React from "react";

const animationReset = { type: 0, name: "", animation: "" };

const SelectAnimation = ({ animation, setAnimation }) => {
  const handleSelectAnimation = (item) => {
    setAnimation(animationReset);
    setTimeout(() => {
      setAnimation(item);
    }, 10);
  };

  return (
    <div className="max-w-[300px] mx-auto mt-5">
      <div className="mb-2 text-center">Please select loading effect</div>
      <div className="flex gap-3 justify-around">
        {Object.values(ANIMATION_TYPE).map((item) => (
          <button
            key={item.type}
            className={classNames(
              "size-16 border border-blue-900 rounded-full text-xs",
              animation.type === item.type &&
                "bg-blue-400 text-white border-none"
            )}
            onClick={() => handleSelectAnimation(item)}
          >
            {item.name}
          </button>
        ))}
      </div>
      <div
        className={classNames(
          "size-[150px] mx-auto border border-slate-400 rounded-lg p-2 mt-5 flex justify-center items-center  box-shadow-custom",
          animation.animation
        )}
      >
        Example example example example example example example...
      </div>
    </div>
  );
};

export default SelectAnimation;
