const FILE_PSD_TYPE = "image/vnd.adobe.photoshop";
const MAX_FILE_SIZE = 100 * 1024 * 1024;

export const calcFileSize = (value = 0) => {
  return Math.floor(value / 1024).toLocaleString("en-US");
};

export const calcTotalFileSize = (files) => {
  const totalSize = files.reduce((total, file) => total + file.size, 0);

  return (totalSize / 1024 / 1024).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const isExistFileInvalid = (files) => {
  let totalSize = 0;
  for (let file of files) {
    if (file.type !== FILE_PSD_TYPE) {
      return true;
    }

    totalSize += file.size;
  }

  return totalSize > MAX_FILE_SIZE;
};

export const createLinkDownload = (url, fileName = "filename") => {
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link?.parentNode?.removeChild(link);
};

export const downloadUrlBinary = (res) => {
  const blob = new Blob([res], { type: "video/mp4" });

  const url = window.URL.createObjectURL(blob);

  createLinkDownload(url, "video.mp4");
};
