import React from "react";
import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <div className="p-10 !pt-4 sm:p-5 w-full max-w-[1280px] mx-auto">
      <h1 className="text-center mb-2">WEBTOON VIEWER</h1>
      <Outlet />
    </div>
  );
};

export default Layout;
