import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_ENDPOINT_API,
  headers: {
    Accept: "application/json, multipart/form-data",
    "Content-Type": "application/json",
  },
});

export const setAuthorizationApi = (token) => {
  instance.defaults.headers.common["Authorization"] = "Bearer " + token;
};

const requestHandler = (request) => {
  if (request.data instanceof FormData) {
    request.headers["Content-Type"] = "multipart/form-data";
  }

  return request;
};

instance.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    if (error.response && error.response.data) {
      return Promise.reject(error.response);
    }

    return Promise.reject(error);
  }
);

export default instance;
