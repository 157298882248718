import api from "./api";

export const uploadPsd = (data) => {
  return api.post("/upload", data);
};

export const getDetailStory = (id) => {
  return api.get(`/story/${id}`);
};

export const exportVideo = (id, data) => {
  return api.post(`/convert-video/${id}`, data);
};

export const downloadVideo = (params) => {
  return api.get("/download_video", { params });
};
