export const ANIMATION_TYPE = {
  SCALE_UP: {
    type: 1,
    name: "Scale Up",
    animation: "animation-scale-up-center",
  },
  FLIP: {
    type: 2,
    name: "Flip",
    animation: "animation-flip-horizontal-bottom",
  },
  SLIDE: { type: 3, name: "Slide", animation: "animation-slide-top" },
  ROTATE: { type: 4, name: "Rotate", animation: "animation-rotate-center" },
};
