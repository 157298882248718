import UploadIcon from "assets/images/UploadIcon";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDropzone } from "react-dropzone";
import CardFileSelect from "./CardFileSelect";
import { calcTotalFileSize, isExistFileInvalid } from "utils";
import ConvertFiles from "./ConvertFiles";
import IconInfo from "assets/images/IconInfo";
import Loading from "./Loading";
import { ANIMATION_TYPE } from "constants";
import SelectAnimation from "./SelectAnimation";
import classNames from "classnames";

const UploadFile = () => {
  const inputRef = useRef(null);

  const [fileList, setFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [animation, setAnimation] = useState(ANIMATION_TYPE.SCALE_UP);

  const isDisableConvert = useMemo(() => {
    return isExistFileInvalid(fileList);
  }, [fileList]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  }, [fileList.length]);

  const fileCheckProcessing = (files) => {
    setFileList((prev) => {
      return [...prev, ...files];
    });
  };

  const onDrop = useCallback((acceptedFiles) => {
    console.log("acceptedFiles", acceptedFiles);
    fileCheckProcessing(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    multiple: true,
  });

  const handleButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileInputChange = (e) => {
    const file = Array.from(e.target.files);

    fileCheckProcessing(file);
  };

  const handleUnSelectFile = (index) => {
    setFileList((prev) => {
      return prev.filter((_, idx) => idx !== index);
    });
  };

  return (
    <div>
      {isLoading && <Loading />}
      {!isSuccess && (
        <>
          <div
            {...getRootProps()}
            className={classNames(
              "bg-slate-100 border border-dashed border-blue-900 rounded-md mb-2",
              { "border-2 bg-slate-400": isDragActive }
            )}
          >
            <input
              {...getInputProps()}
              ref={inputRef}
              type="file"
              // accept=".psd"
              multiple
              style={{ display: "none" }}
              onChange={handleFileInputChange}
            />

            <div className="flex flex-col items-center">
              <UploadIcon />
              <p className="text-sm text-[#333] mb-2">
                Drag and drop your PDS files
              </p>
              <p className="text-sm text-[#333] mb-2">or</p>
              <button
                className="h-10 px-3 mb-4 bg-blue-900 text-white rounded-md sm:text-sm"
                type="button"
                onClick={handleButtonClick}
              >
                Select file
              </button>
            </div>
          </div>
          <p className="text-xs text-slate-700 flex">
            <IconInfo width={16} height={16} fill="#334155" />
            You can select multiple PSD files with a total files size of less
            than 100MB.
          </p>
        </>
      )}

      {fileList.length > 0 && (
        <div>
          <h3 className="mt-10 mb-2">
            Total File Size: {calcTotalFileSize(fileList)} MB
          </h3>
          <div className="flex flex-wrap gap-x-2 gap-y-1 border border-slate-400 p-3 rounded-xl relative">
            {fileList?.map((item, index) => (
              <CardFileSelect
                key={index}
                file={item}
                handleClearFile={() => handleUnSelectFile(index)}
                isLoading={isLoading}
                isSuccess={isSuccess}
              />
            ))}
            {!isSuccess && (
              <div className="w-full flex justify-end">
                <button
                  className="bg-rose-400 px-2 py-1 rounded-lg text-xs"
                  onClick={() => setFileList([])}
                >
                  Clear all
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      {!isSuccess && (
        <SelectAnimation animation={animation} setAnimation={setAnimation} />
      )}

      {fileList.length > 0 && (
        <ConvertFiles
          files={fileList}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          isSuccess={isSuccess}
          setIsSuccess={setIsSuccess}
          disabled={isDisableConvert}
          animation={animation}
        />
      )}
    </div>
  );
};

export default UploadFile;
