import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "components/layouts/Layout";
import HomePage from "pages/Home";
import MangaViewer from "pages/MangaViewer";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/story/:slug" element={<MangaViewer />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
