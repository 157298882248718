import IconArrowRight from "assets/images/IconArrowRight";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { exportVideo, uploadPsd } from "services/webtoon";
import { createLinkDownload } from "utils";

const url = process.env.REACT_APP_PUBLIC_ENDPOINT_API;

const ConvertFiles = ({
  files = [],
  isLoading,
  setIsLoading,
  isSuccess,
  setIsSuccess,
  disabled,
  animation,
}) => {
  const navigate = useNavigate();

  const [progress, setProgress] = useState(0);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [storyId, setStoryId] = useState();

  const uploadFilePsd = async (files) => {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append("psdFiles", file);
    });
    formData.append("animation_type", animation.type);

    return await uploadPsd(formData);
  };

  useEffect(() => {
    let interval;

    if (isLoadingDownload) {
      interval = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress < 60) {
            return prevProgress + 1;
          } else if (prevProgress < 75) {
            return prevProgress + 0.5;
          } else {
            clearInterval(interval);
            return prevProgress;
          }
        });
      }, 100);
    }

    return () => clearInterval(interval);
  }, [isLoadingDownload]);

  useEffect(() => {
    let interval;

    if (isLoading) {
      interval = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress < 60) {
            return prevProgress + 1;
          } else if (prevProgress < 75) {
            return prevProgress + 0.5;
          } else {
            clearInterval(interval);
            return prevProgress;
          }
        });
      }, 100);
    }

    return () => clearInterval(interval);
  }, [isLoading]);

  useEffect(() => {
    setIsSuccess(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const handleUploadFile = async () => {
    try {
      setIsLoading(true);
      setProgress(0);

      const response = await uploadFilePsd(files);

      const interval = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(interval);
            setIsLoading(false);
            setIsSuccess(true);
            setStoryId(response?.story_id);
            return 100;
          }
          return prevProgress + 5;
        });
      }, 300);
    } catch (error) {
      setIsLoading(false);
      alert("Convert error!");
    }
  };

  const handleDownloadVideo = async () => {
    if (!storyId) return;
    try {
      setIsLoadingDownload(true);
      setIsLoading(true);
      setProgress(0);

      const formData = new FormData();

      const response = await exportVideo(storyId, formData);
      if (!response?.video_url) {
        alert("Download failed!");
        return;
      }

      const interval = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(interval);
            setIsLoadingDownload(false);
            setStoryId(response?.story_id);
            return 100;
          }
          return prevProgress + 5;
        });
      }, 300);

      createLinkDownload(
        `${url}/download_video?file_name=${response.video_url}`,
        "video.mp4"
      );
    } catch (error) {
      alert("Download failed!");
    } finally {
      setIsLoadingDownload(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {files.length > 0 && (
        <>
          <button
            className={classNames(
              "text-white bg-blue-900 pl-4 pr-2 py-2 rounded-lg flex items-center gap-x-2 mx-auto mt-4",
              { "hidden ": isLoading || isSuccess }
            )}
            onClick={handleUploadFile}
          >
            Convert
            <IconArrowRight fill="white" />
          </button>

          {isSuccess && (
            <div className="mt-4 flex justify-center items-center gap-2">
              <Link
                to={`/story/${storyId}`}
                target="_blank"
                className="min-w-[100px] text-center px-3 py-1 rounded-lg bg-white border border-blue-900 text-blue-900"
              >
                Preview
              </Link>
              <button
                className="min-w-[100px] px-3 py-1 rounded-lg bg-blue-900 text-white"
                onClick={handleDownloadVideo}
              >
                Download .mp4
              </button>
            </div>
          )}

          {(isLoading || isLoadingDownload) && (
            <div className="h-4 w-full border border-slate-400 rounded-lg overflow-hidden mt-3">
              <div
                className="bg-cyan-500 transition-all-linear h-full"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          )}

          {isSuccess && !isLoadingDownload && (
            <button
              className="p-2 rounded-xl text-sm block mx-auto mt-4 underline"
              onClick={() => navigate(0)}
            >
              Convert more files
            </button>
          )}
        </>
      )}
    </>
  );
};

export default ConvertFiles;
