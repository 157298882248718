import React from "react";

const IconFile = React.forwardRef((props, ref) => {
  return (
    <svg
      ref={ref}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 50 50"
      style={{ enableBackground: "new 0 0 303.188 303.188" }}
      xmlSpace="preserve"
      width="30"
      height="30"
      {...props}
    >
      <g>
        <path
          style={{ fill: "#E8E8E8" }}
          d="M36.252 0H5.416v50h39.168V8.332Z"
        />
        <path
          style={{ fill: "#38A3FF" }}
          d="M37.541 4.166h-32.125V0h30.835Z"
        />
        <g>
          <path
            style={{ fill: "#A4A9AD" }}
            d="M20.707 39.77q0 1.326 -0.782 2.049t-2.221 0.723h-0.601v2.625h-2.138V37.253h2.739q1.499 0 2.252 0.655t0.752 1.861m-3.604 1.028h0.39q0.481 0 0.766 -0.271 0.284 -0.271 0.284 -0.747 0 -0.801 -0.887 -0.801h-0.552z"
          />
          <path
            style={{ fill: "#A4A9AD" }}
            d="M27.142 42.762q0 0.736 -0.373 1.307t-1.077 0.887 -1.651 0.317q-0.79 0 -1.326 -0.111t-1.115 -0.387v-1.905a6.35 6.35 0 0 0 1.272 0.49q0.66 0.176 1.212 0.176 0.476 0 0.698 -0.165t0.222 -0.425q0 -0.163 -0.089 -0.284 -0.089 -0.121 -0.286 -0.246 -0.198 -0.124 -1.053 -0.509 -0.774 -0.352 -1.161 -0.682t-0.574 -0.758q-0.186 -0.427 -0.186 -1.012 0 -1.093 0.795 -1.704c0.795 -0.612 1.26 -0.612 2.187 -0.612q1.228 0 2.506 0.569l-0.655 1.651q-1.109 -0.509 -1.916 -0.509 -0.417 0 -0.606 0.146 -0.19 0.146 -0.19 0.362 0 0.233 0.241 0.416 0.241 0.184 1.307 0.671 1.023 0.46 1.421 0.988t0.398 1.329"
          />
          <path
            style={{ fill: "#A4A9AD" }}
            d="M35.081 41.041q0 1.98 -1.091 3.053c-1.091 1.072 -1.749 1.071 -3.066 1.071h-2.56V37.252h2.739q1.905 0 2.942 0.974t1.036 2.814m-2.219 0.076q0 -1.088 -0.43 -1.613t-1.307 -0.525h-0.623v4.433h0.476q0.975 0 1.429 -0.566 0.455 -0.566 0.455 -1.729"
          />
        </g>
        <g>
          <g>
            <path
              style={{ fill: "#38A3FF" }}
              d="M25.436 17.95q0 2.447 -1.529 3.743t-4.349 1.296h-1.379v5.391h-3.215V13.222h4.841q2.757 0 4.194 1.187t1.436 3.541m-7.257 2.405h1.058q1.482 0 2.219 -0.586t0.736 -1.706q0 -1.129 -0.617 -1.669 -0.617 -0.539 -1.933 -0.539h-1.462z"
            />
            <path
              style={{ fill: "#38A3FF" }}
              d="M36.135 24.938q0 1.783 -1.239 2.716t-3.707 0.933q-1.265 0 -2.156 -0.171t-1.669 -0.503v-2.612q0.881 0.415 1.986 0.695t1.944 0.28q1.721 0 1.721 -0.996 0 -0.373 -0.228 -0.607t-0.788 -0.528c-0.56 -0.295 -0.871 -0.426 -1.493 -0.69q-1.337 -0.559 -1.964 -1.037 -0.627 -0.476 -0.912 -1.093t-0.285 -1.519q0 -1.544 1.198 -2.389c1.198 -0.845 1.93 -0.845 3.396 -0.845q2.094 0 4.074 0.912l-0.954 2.281q-0.871 -0.373 -1.627 -0.612a5.1 5.1 0 0 0 -1.545 -0.238q-1.399 0 -1.399 0.757 0 0.425 0.451 0.736c0.451 0.311 0.959 0.515 1.975 0.922q1.358 0.549 1.99 1.026t0.933 1.099 0.301 1.482"
            />
          </g>
        </g>
        <path
          style={{ fill: "#D1D3D3" }}
          d="M36.252 8.332h8.332L36.252 0Z"
        />
      </g>
    </svg>
  );
});

IconFile.displayName = "IconFile";

export default IconFile;
