import React from "react";

const IconInfo = React.forwardRef((props, ref) => {
  return (
    <svg
      ref={ref}
      fill="#1e3a8a"
      width="30px"
      height="30px"
      viewBox="0 -64 640 640"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336l24 0 0-64-24 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l48 0c13.3 0 24 10.7 24 24l0 88 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-80 0c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/>
    </svg>
  );
});

IconInfo.displayName = "IconInfo";

export default IconInfo;
